import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table className="field-table api-ref">
      <thead>
    <tr>
      <th>Name /<br />Type</th>
      <th>Description</th>
    </tr>
  </thead>
      <tbody>
        <tr>
          <td colSpan="2">
            <p><strong parentName="p">{`Operation options`}</strong></p>
          </td>
        </tr>
        <tr className="required">
          <td>
            <h6 {...{
              "id": "from"
            }}><a parentName="h6" {...{
                "href": "#from"
              }}><inlineCode parentName="a">{`from`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`string | StoreObject | Reference`}</inlineCode></p>
          </td>
          <td>
            <p><strong parentName="p">{`Required.`}</strong>{` An object containing a `}<inlineCode parentName="p">{`__typename`}</inlineCode>{` and primary key fields (such as `}<inlineCode parentName="p">{`id`}</inlineCode>{`) identifying the entity object from which the fragment will be retrieved, or a `}<inlineCode parentName="p">{`{ __ref: "..." }`}</inlineCode>{` reference, or a `}<inlineCode parentName="p">{`string`}</inlineCode>{` ID (uncommon).`}</p>
          </td>
        </tr>
        <tr className="required">
          <td>
            <h6 {...{
              "id": "fragment"
            }}><a parentName="h6" {...{
                "href": "#fragment"
              }}><inlineCode parentName="a">{`fragment`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`DocumentNode`}</inlineCode></p>
          </td>
          <td>
            <p><strong parentName="p">{`Required.`}</strong>{` A GraphQL fragment document parsed into an AST with the `}<inlineCode parentName="p">{`gql`}</inlineCode>{` template literal.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "fragmentname"
            }}><a parentName="h6" {...{
                "href": "#fragmentname"
              }}><inlineCode parentName="a">{`fragmentName`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`string`}</inlineCode></p>
          </td>
          <td>
            <p>{`The name of the fragment defined in the `}<a parentName="p" {...{
                "href": "#fragment"
              }}><inlineCode parentName="a">{`fragment`}</inlineCode></a>{` document to use in the call.`}</p>
            <p><strong parentName="p">{`Required`}</strong>{` if the `}<inlineCode parentName="p">{`fragment`}</inlineCode>{` document includes more than one fragment, optional otherwise.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "optimistic"
            }}><a parentName="h6" {...{
                "href": "#optimistic"
              }}><inlineCode parentName="a">{`optimistic`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`boolean`}</inlineCode></p>
          </td>
          <td>
            <p>{`If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, `}<inlineCode parentName="p">{`readFragment`}</inlineCode>{` returns optimistic results.`}</p>
            <p>{`The default value is `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "variables"
            }}><a parentName="h6" {...{
                "href": "#variables"
              }}><inlineCode parentName="a">{`variables`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`{ [key: string]: any }`}</inlineCode></p>
          </td>
          <td>
            <p>{`An object containing all of the GraphQL variables your fragment requires.`}</p>
            <p>{`Each key in the object corresponds to a variable name, and that key's value corresponds to the variable value.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "canonizeresults"
            }}><a parentName="h6" {...{
                "href": "#canonizeresults"
              }}><inlineCode parentName="a">{`canonizeResults`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`boolean`}</inlineCode></p>
          </td>
          <td>
            <blockquote>
              <p parentName="blockquote"><strong parentName="p"><undefined parentName="strong"><span {...{
                      "role": "img",
                      "aria-label": "warning"
                    }}>{`⚠️`}</span>{` Deprecated`}</undefined></strong>{`:
Using `}<inlineCode parentName="p">{`canonizeResults`}</inlineCode>{` can result in memory leaks so we generally do not recommend using this option anymore.
A future version of Apollo Client will contain a similar feature without the risk of memory leaks.`}</p>
            </blockquote>
            <p>{`If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, result objects read from the cache will be `}<em parentName="p">{`canonized`}</em>{`, which means deeply-equal objects will also be `}<inlineCode parentName="p">{`===`}</inlineCode>{` (literally the same object), allowing much more efficient comparison of past/present results.`}</p>
            <p>{`The default value is `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      