import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table className="field-table api-ref">
      <thead>
    <tr>
      <th>Name /<br />Type</th>
      <th>Description</th>
    </tr>
  </thead>
      <tbody>
        <tr>
          <td colSpan="2">
            <p><strong parentName="p">{`Operation result`}</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "data"
            }}><a parentName="h6" {...{
                "href": "#data"
              }}><inlineCode parentName="a">{`data`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`TData`}</inlineCode></p>
          </td>
          <td>
            <p>{`An object containing the data for a given GraphQL fragment.`}</p>
            <p>{`This value might be `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` if a query results in one or more errors (depending on the query's `}<inlineCode parentName="p">{`errorPolicy`}</inlineCode>{`).`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "complete"
            }}><a parentName="h6" {...{
                "href": "#complete"
              }}><inlineCode parentName="a">{`complete`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`boolean`}</inlineCode></p>
          </td>
          <td>
            <p>{`A boolean indicating whether the data returned for the fragment is complete. When `}<inlineCode parentName="p">{`false`}</inlineCode>{`, the `}<inlineCode parentName="p">{`missing`}</inlineCode>{` field should explain which fields were responsible for the incompleteness.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "missing"
            }}><a parentName="h6" {...{
                "href": "#missing"
              }}><inlineCode parentName="a">{`missing`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`MissingTree`}</inlineCode></p>
          </td>
          <td>
            <p>{`A tree of all `}<inlineCode parentName="p">{`MissingFieldError`}</inlineCode>{` messages reported during fragment reading, where the branches of the tree indicate the paths of the errors within the query result.`}</p>
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      