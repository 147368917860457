import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table className="field-table api-ref">
      <thead>
  <tr>
    <td>Name /<br />Type</td>
    <td>Description</td>
  </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="2">
            <p><strong parentName="p">{`Operation result`}</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "data"
            }}><a parentName="h6" {...{
                "href": "#data"
              }}><inlineCode parentName="a">{`data`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`TData`}</inlineCode></p>
          </td>
          <td>
            <p>{`An object containing the result of your GraphQL query after it completes.`}</p>
            <p>{`This value might be `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` if a query results in one or more errors (depending on the query's `}<inlineCode parentName="p">{`errorPolicy`}</inlineCode>{`).`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "error"
            }}><a parentName="h6" {...{
                "href": "#error"
              }}><inlineCode parentName="a">{`error`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`ApolloError`}</inlineCode></p>
          </td>
          <td>
            <p>{`If the query produces one or more errors, this object contains either an array of `}<inlineCode parentName="p">{`graphQLErrors`}</inlineCode>{` or a single `}<inlineCode parentName="p">{`networkError`}</inlineCode>{`. Otherwise, this value is `}<inlineCode parentName="p">{`undefined`}</inlineCode>{`.`}</p>
            <p>{`This property can be ignored when using the default `}<inlineCode parentName="p">{`errorPolicy`}</inlineCode>{` or an `}<inlineCode parentName="p">{`errorPolicy`}</inlineCode>{` of `}<inlineCode parentName="p">{`none`}</inlineCode>{`. The hook will throw the error instead of setting this property.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "networkstatus"
            }}><a parentName="h6" {...{
                "href": "#networkstatus"
              }}><inlineCode parentName="a">{`networkStatus`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`NetworkStatus`}</inlineCode></p>
          </td>
          <td>
            <p>{`A number indicating the current network state of the query's associated request. `}<a parentName="p" {...{
                "href": "https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts#L4"
              }}>{`See possible values.`}</a></p>
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      